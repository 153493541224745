import ErrorComponent from 'components/errorPage/ErrorComponent';
import HeadMetadata from 'components/shared/HeadMetadata';
import { useI18n } from 'hooks/useI18n';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const ErrorPage = (): JSX.Element => {
  const { t } = useI18n('errorPage');

  return (
    <>
      <HeadMetadata title={t('404.title')} desc={t('404.heading')} />
      <ErrorComponent errorCode={404} />
    </>
  );
};

export default ErrorPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || '', [
        'common',
        'topbar',
        'footer',
        'errorPage'
      ]))
    },
    revalidate: 30
  };
};
